import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useAfricarStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      bannerCase: file(
        relativePath: {
          eq: "case-study/africar/fast-loading-multi-vendor-car-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredOne: file(
        relativePath: {
          eq: "case-study/africar/pwa-for- the-car-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredTwo: file(
        relativePath: {
          eq: "case-study/africar/multi-site-automotive-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredThree: file(
        relativePath: {
          eq: "case-study/africar/sleek-design-of-the-car-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFour: file(
        relativePath: {
          eq: "case-study/africar/ad-posting-functionality-on-the-car-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFive: file(
        relativePath: {
          eq: "case-study/africar/communication-functionality-on-the-car-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSix: file(
        relativePath: {
          eq: "case-study/africar/advanced-search-filters-on-the-car-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      videoCaseImage: file(
        relativePath: { eq: "case-study/africar/video-case.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)
  return query
}

export default useAfricarStaticQuery
