import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { QueryResult, StrapiCase } from '~/types/graphql'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import CaseStudiesAfricar from '~/views/CaseStudies/views/Africar'

const AfricarPage = () => <CaseStudiesAfricar />

export default AfricarPage

export const query = graphql`
  query {
    strapiCase(page_name: { eq: "Multi vendor vehicle marketplace" }) {
      ...MetaTagInformationCases
    }
  }
`

export const Head = ({ data }: QueryResult<StrapiCase>) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiCase,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
      url="https://www.codica.com/case-studies/multi-vendor-vehicle-marketplace/"
    />
  )
}
