// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bG_kr";
export var caseStudyBackground__lineColor = "bG_kp";
export var caseStudyHead__imageWrapper = "bG_kk";
export var caseStudyProjectsSection = "bG_ks";
export var caseStudyQuote__bgLight = "bG_kt";
export var caseStudyQuote__bgRing = "bG_km";
export var caseStudySolution__ring = "bG_ky";
export var caseStudyVideo__ring = "bG_kv";
export var caseStudy__bgDark = "bG_kh";
export var caseStudy__bgLight = "bG_kg";